<template>
  <div class="new-campaign">
    <want-to-back
      v-if="wantToBack"
      @emitCancelSave="emitCancelSave"
      @emitSaveBefore="withoutSave"
    />
    <div class="arrow-section fixed w-full z-10">
      <the-header class="" />
    </div>
    <div
      class="arrow cursor-pointer pl-4"
      @click="checkIsAlreadySave"
    >
      <svg-icon
        name="ic-arrow-back"
        class="w-8 h-8"
        original
      />
    </div>
    <div class="flex justify-between w-full flex-section">
      <div class="edit-section overflow-y-scroll flex-1 w-1/2 p-6 bg-white">
        <sms-campaign-infos
          :is-update="updateState"
          :campaign-for-u="editableCampaign"
          :is-update-image="isUpdateImage"
          @getCampaignName="getCampaignName"
          @getCampaignText="getCampaignText"
          @selectedImages="getSelectedImages"
          @choosedButton="choosedButton"
          @getButtonLink="getButtonLink"
          @emitLikelyReach="getLikelyReach"
          @emitTotalCost="emitTotalCost"
          @successSave="successSave"
        />
      </div>
      <div class="flex-1 preview-section ml-3">
        <div class="fixed-section fixed w-1/3">
          <div class="overflow-y-scroll overflow-y-scroll scroll-section">
            <div class=" p-6 pb-3 w-full bg-white">
              <new-sms-campaign-preview
                :post-description="campaignText"
                :image-data="selectedImages.imageDataUrl"
                :chosed-button="campaignButton"
              />
            </div>
            <div class="stat-section mt-6 p-6 bg-white w-full">
              <div class="flex w-full">
                <div class="flex-1 mr-1">
                  <h3 class="campaign-port font-normal text-xl">
                    {{ $t('portProba') }}
                    <span>
                      <svg-icon
                        slot="reference"
                        v-tippy
                        :content="$t('portPopup')"
                        name="ic-tooltip-icon"
                        class="w-6 h-6  ml-2 pt-1 top"
                        original
                      />
                    </span>
                  </h3>
                  <div class="amount-and-port  h-24 mt-2 rounded  text-2xl p-8 align-middle">
                    {{ nbrDoc }} <span class="text-base uppercase">Professionnels de santé</span>
                  </div>
                </div>
                <!-- Hiden sms cost -->
                <!-- <div class="flex-1 ml-1">
                  <h3 class="campaign-port font-normal text-xl">
                    {{ $t('totalCost') }}
                    <span>
                      <svg-icon
                        slot="reference"
                        v-tippy
                        :content="$t('totalCostPopup') + $t('totalCostPopupTwo')"
                        name="ic-tooltip-icon"
                        class="w-6 h-6  ml-2 pt-1"
                        original
                      />
                    </span>
                  </h3>
                  <div class="amount-and-port  h-24 mt-2 rounded  text-2xl p-8 align-middle">
                    € {{ totalCost }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { firebaseDb } from '../../main'
// import { firebaseRef } from '../../rema-function/firebaseRef'
import { remaDefaultData } from '../../rema-function/remaDefaultData'
import ClickOutside from 'vue-click-outside'
import TheHeader from '../../components/the-header'
import WantToBack from '../../components/helper/rema-components/want-to-back'
import SmsCampaignInfos from '../../components/helper/rema-components/sms-campaign-infos'
import NewSmsCampaignPreview from '../../components/helper/rema-components/new-sms-campaign-preview'
import store from '../../store'
export default {
  name: 'Index',
  components: { NewSmsCampaignPreview, SmsCampaignInfos, WantToBack, TheHeader },
  directives: {
    ClickOutside
  },
  data () {
    return {
      campaignName: '',
      campaignText: '',
      selectedImages: {
        imagesReal: [],
        imageDataUrl: []
      },
      campaignButton: {},
      campaignButtonLink: '',
      editableCampaign: {},
      isUpdateImage: [],
      nbrDoc: 0,
      totalCost: 0,
      campaignId: '',
      updateState: false,
      isAlreadySave: false,
      wantToBack: false
    }
  },
  computed: {
    getCampaign: function () {
      return store.getters.getCurrentPost
    }
  },
  async created () {
    let vm = this
    if (this.$route.params.id !== 'new-sms-campaign') {
      this.updateState = true
      let splitedArray = this.$route.params.id.split('&type=')
      // let campaignId = splitedArray[0]
      let campaingnType = splitedArray[1]
      // let ref = ''
      if (campaingnType === 'inactive') {
      //  ref = firebaseDb.ref(firebaseRef.mainsSMSAdsFeeds)
      } else if (campaingnType === 'draft') {
        // ref = firebaseDb.ref(firebaseRef.draftSMSFeeds)
      } else {
      //  ref = firebaseDb.ref(firebaseRef.mainsSMSAdsFeeds)
      }
      /* await ref.child(campaignId).once('value', function (snapshot) {
        vm.editableCampaign = snapshot.val()
      }) */
      vm.editableCampaign = this.getCampaign
      this.campaignName = this.getCampaign.title
      this.campaignText = this.getCampaign.textContent
    }
  },
  methods: {
    getCampaignName (answer) {
      this.campaignName = answer
    },
    getCampaignText (answer) {
      this.campaignText = answer
    },
    getSelectedImages (answer) {
      this.selectedImages = answer
    },
    choosedButton (answer) {
      this.campaignButton = answer
    },
    getButtonLink (answer) {
      this.campaignButtonLink = answer
    },
    getLikelyReach (answer) {
      this.nbrDoc = answer
    },
    emitTotalCost (answer) {
      this.totalCost = answer
    },
    convertToEuro (cout, deviseMoney) {
      if (deviseMoney.text === '€') {
        return cout
      } else {
        return Math.round(cout / remaDefaultData.baseEuro)
      }
    },
    FromUrlToDataURL (url, cb) {
      // This can be downloaded directly:
      var xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = function (event) {
        // var blob = xhr.response
        let reader = new FileReader()
        reader.onload = (e) => {
          cb(e.target.result)
          // alert('image _______: ' + e.target.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.send()
    },
    successSave () {
      this.isAlreadySave = true
    },
    checkIsAlreadySave () {
      if (!this.isAlreadySave && this.campaignText !== '' && this.campaignName !== '') {
        this.wantToBack = true
      } else {
        this.$store.dispatch('createState', true)
        this.$router.push('/cards')
      }
    },
    emitCancelSave () {
      this.wantToBack = false
    },
    withoutSave () {
      this.wantToBack = false
      this.$store.dispatch('createState', true)
      this.$router.push('/cards')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/style/sass/variables";
  .title-section {
    background-color: $Rema-Content-Background;;
  }

  .new-campaign {
    width: 100%;
  }
  .divi {
    background-color: $Rema-Icon-Background;
  }
  .amount-and-port{
    background-color: $Rema-Content-Background;
  }
  .popper {
    box-shadow: none;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    color: black;
    font-size: .7rem;
    text-align: left;
  }
  .fixed-section {
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
    height: 80%;
    width: 38%;
  }
  ::-webkit-scrollbar  {
    width: 0px;
  }
  .flex-section {
    width: 80%;
    margin: auto;
  }
  .new-campaign {
    background-color: $Rema-Content-Background;
  }
  .edit-section {
    margin-top: 2%;
  }
  .preview-section {
    margin-top: 1.8%;
  }
  .arrow {
    padding-top: 6%;
  }
</style>
