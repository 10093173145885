<template>
  <div class="canpaigns-infos ">
    <div
      class="hidden button bg-red p-4 w-4 text-white cursor-pointer"
      @click="createSpecificPostData"
    >
      Update contries
    </div>
    <my-custom-loader v-if="showLoader" />
    <div class="title-section">
      <h3 class=" cursor-pointer create-canpaign-title text-2xl font-bold uppercase">
        {{ $t('createCanpaignSms') }}
      </h3>
      <div class=" mt-1 divider h-2  w-1/4" />
    </div>
    <div class="form-section mt-6">
      <div
        v-if="audience.length !== 0"
        class="campaign-audience-section"
      >
        <h3 class="campaign-button font-normal text-xl">
          {{ $t('saveAudience') }}
        </h3>
        <h3 class="campaign-button-subtitle font-light text-base mt-1">
          {{ $t('choseAudience') }}
        </h3>
        <VueMultiselect
          v-model="selectedAudience"
          class="custom-style-tag border-none  mt-2"
          placeholder="Choisir une audience"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          track-by="key"
          label="name"
          tag-placeholder=""
          :multiple="false"
          :options="audience"
          @input="updateAudience"
        />
      </div>
      <div class="campaign-name-section mt-4">
        <h3 class="campaign-name text-xl font-normal">
          {{ $t('campaignName') }}
        </h3>
        <h3 class="campaign-name-subtitle font-light text-base mt-1">
          {{ $t('notVisibleName') }}
        </h3>
        <input
          v-model="campaignName"
          class="mt-2 border w-full px-2 py-4 rounded"
          :placeholder="$t('placeOne')"
          @input="emitCampaignName"
        >
      </div>
      <div class="campaign-text-section mt-4 ">
        <h3 class="campaign-text font-normal text-xl">
          {{ $t('text') }}
        </h3>
        <h3 class="campaign-name-subtitle font-light text-base mt-1">
          {{ maxChar }} {{ $t('charRestant') }}
        </h3>
        <textarea
          v-model="campaignText"
          class="px-2 py-4 border mt-2 rounded w-full campaign-text-area"
          :placeholder="$t('placeTwo')"
          :maxlength="160 - entreprise.fullname.length"
          @input="emitCampaignText"
        />
      </div>
      <div class="campaign-images-section mt-4 hidden">
        <h3 class="campaign-images font-normal text-xl">
          {{ $t('images') }}
        </h3>
        <h3 class="campaign-name-subtitle font-light text-base mt-1">
          {{ $t('imagesSubtitle') }}
        </h3>
        <div class="mt-3 w-full">
          <div class="image-list flex justify-start flex-wrap">
            <div
              v-for="(item, key) in images.imageDataUrl"
              :key="item+key"
              class="w-16 h-16 m-1 border rounded"
            >
              <H3
                class="relative ml-16 -mt-1 cursor-pointer float-right"
                @click="deleteImage(key)"
              >
                <svg-icon
                  name="ic_delete"
                  class="w-4 h-4"
                  original
                />
              </H3>
              <img
                :src="item"
                class="selected-image -mt-4"
              >
            </div>
            <div
              class="add-section w-16 h-16 m-1 border rounded text-center align-middle cursor-pointer"
              @click="showSelect"
            >
              <svg-icon
                name="ic-add"
                class="w-4 h-4"
                original
              />
            </div>
          </div>
        </div>
      </div>
      <div class="campaign-button-section mt-4 hidden">
        <h3 class="campaign-button font-normal text-xl">
          {{ $t('actionButton') }}
        </h3>
        <h3 class="campaign-button-subtitle font-light text-base mt-1">
          {{ $t('actionButtonSubtitle') }}
        </h3>
        <VueMultiselect
          v-model="selectedButton"
          class="custom-style-tag border-none  mt-2"
          :placeholder="$t('defaultButton')"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          track-by="id"
          label="label"
          tag-placeholder=""
          :multiple="false"
          :custom-label="customLabelButton"
          :options="actionButtons"
          @input="emitActionButton"
        />
        <div class="button-link">
          <input
            v-model="buttonLink"
            type="url"
            class="mt-2 border w-full px-2 py-4 rounded"
            :placeholder="$t('buttonLink')"
            @input="emitButtonLink"
            @focus="setDefaultStart"
          >
        </div>
      </div>
      <div class="campaign-date-section mt-4">
        <h3 class="campaign-start-date font-normal text-xl">
          {{ $t('startDate') }}
          <span>
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('datePopup')"
              name="ic-tooltip-icon"
              class="w-6 h-6  ml-2 pt-1 top"
              original
            />
          </span>
        </h3>
        <datetime
          v-model="campaignStartDate"
          type="date"
          format="dd-MM-yyyy"
          :min-datetime="new Date().toISOString()"
          :placeholder="new Date().toLocaleDateString('en-GB')"
          class="border h-full w-full px-2 py-4 mt-2 rounded  my-date-time"
        />
      </div>
      <div class="campaign-day-section mt-4 hidden">
        <h3 class="campaign-day font-normal text-xl">
          {{ $t('durationInDay') }}
          <span>
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('durationInDayLabelThree') + '. ' + $t('durationInDayLabelTwo')"
              name="ic-tooltip-icon"
              class="w-6 h-6  ml-2 pt-1 top"
              original
            />
          </span>
        </h3>
        <input
          v-model="nbrDay"
          type="number"
          min="1"
          class="border h-full w-full px-2 py-4 mt-2 rounded"
        >
      </div>
      <div class="campaign-country-section mt-4">
        <h3 class="campaign-country font-normal text-xl">
          {{ $t('countryCible') }}
        </h3>
        <VueMultiselect
          v-model="selectedCountry"
          class="custom-style-tag border-none  mt-2"
          :options="countryList"
          :multiple="true"
          :taggable="true"
          :show-labels="false"
          tag-placeholder=""
          label="name"
          :placeholder="$t('chooseCountry')"
          track-by="text"
        />
      </div>
      <div class="campaign-country-section mt-4">
        <h3 class="campaign-country font-normal text-xl">
          {{ $t('cityCible') }}
          <span>
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('cityClibleTooltip')"
              name="ic-tooltip-icon"
              class="w-6 h-6  ml-2 pt-1 top"
              original
            />
          </span>
        </h3>
        <VueMultiselect
          v-model="selectedCity"
          class="custom-style-tag border-none  mt-2"
          :options="allSpeCities.slice().reverse()"
          :multiple="true"
          :taggable="true"
          :show-labels="false"
          label="city"
          tag-placeholder=""
          :placeholder="$t('chooseCity')"
          track-by="city"
        />
      </div>
      <div class="campaign-date-section mt-4">
        <h3 class="campaign-start-date font-normal text-xl">
          {{ $t('targetProfession') }}
          <span>
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('jobPopup')"
              name="ic-tooltip-icon"
              class="w-6 h-6  ml-2 pt-1 top"
              original
            />
          </span>
        </h3>
        <VueMultiselect
          v-model="selectedProfession"
          class="custom-style-tag border-none  mt-2"
          :options="professionsData"
          :multiple="true"
          :taggable="true"
          label="text"
          :show-labels="false"
          :placeholder="$t('allProfession')"
          track-by="text"
        />
      </div>
      <div class="campaign-age-section mt-4">
        <h3 class="campaign-age-title font-normal text-xl">
          {{ $t('age') }}
          <span>
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('agePopup')"
              name="ic-tooltip-icon"
              class="w-6 h-6  ml-2 pt-1 top"
              original
            />
          </span>
        </h3>
        <VueMultiselect
          v-model="selectedAge"
          class="custom-style-tag border-none  mt-2"
          :options="ageData"
          :multiple="true"
          :taggable="true"
          :show-labels="false"
          :placeholder="$t('allAge')"
          :custom-label="customLabel"
          track-by="text"
        />
      </div>
      <div class="campaign-genre-section mt-4">
        <h3 class="campaign-genre-title  font-normal text-xl">
          {{ $t('genre') }}
          <span>
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('genrePopup')"
              name="ic-tooltip-icon"
              class="w-6 h-6  ml-2 pt-1 top"
              original
            />
          </span>
        </h3>
        <VueMultiselect
          v-model="selectedSex"
          class="custom-style-tag border-none  mt-2"
          :options="sexData"
          :show-labels="false"
          :multiple="false"
          :taggable="true"
          :placeholder="$t('all')"
          :custom-label="customLabelSex"
          track-by="id"
        />
      </div>
      <div class="campaign-cout-section mt-4 flex">
        <div class="flex-1 mr-1">
          <h3 class="campaign-port font-normal font-normal text-xl">
            {{ $t('portProba') }}
            <span>
              <svg-icon
                slot="reference"
                v-tippy
                :content="$t('portPopup')"
                name="ic-tooltip-icon"
                class="w-6 h-6  ml-2 pt-1 top"
                original
              />
            </span>
          </h3>
          <div class="amount-and-port  h-24 mt-2 rounded  text-2xl p-8 align-middle">
            {{ posterValue }} <span class="text-base uppercase">PROFESSIONNELS DE SANTÉ</span>
          </div>
        </div>
        <!-- Hiden sms cost -->
        <!-- <div class="flex-1 ml-1">
          <h3 class="campaign-port font-normal text-xl">
            {{ $t('totalCost') }}
            <span>
              <svg-icon
                slot="reference"
                v-tippy
                :content="$t('totalCostPopup') + $t('totalCostPopupTwo')"
                name="ic-tooltip-icon"
                class="w-6 h-6  ml-2 pt-1 top"
                original
              />
            </span>
          </h3>
          <div class="amount-and-port  h-24 mt-2 rounded  text-2xl p-8 align-middle">
            € {{ totalCost }}
          </div>
        </div> -->
      </div>
      <div class="start-campaign-button  mt-4">
        <button
          class="px-3 py-4 bg-transaprent text-green text-xl text-center uppercase button rounded w-full"
          @click="savePublic"
        >
          {{ $t('savePublic') }}
        </button>
      </div>
      <div class="start-campaign-button  mt-4">
        <button
          class="px-3 py-4 bg-green text-xl text-center text-white uppercase button rounded w-full"
          @click="publishPost"
        >
          {{ $t('publish') }}
        </button>
      </div>
      <h3
        class="mt-2 uppercase text-grey text-center font-normal cursor-pointer"
        @click="wantToRecharge(false)"
      >
        {{ $t('saveAsdraft') }}
      </h3>
    </div>
    <confirm-post-debit
      v-if="wantToConfirmCreate"
      :amount="totalCost"
      @emitCloseConfirm="emitCloseConfirm"
      @emitSaveCreate="saveNewPost"
      @emitCancelCreate="emitCloseConfirm"
    />
    <insufficient-amount
      v-if="insufficientAmount"
      @wantToRecharge="wantToRecharge(true)"
      @emitCloseInsufficient="emitCloseInsufficient"
    />
    <success-create
      v-if="sucessCreate"
      @emitCloseSuccess="emitCloseSuccess"
      @emitSuccessCreateEnd="emitSuccessCreateEnd"
    />
    <account-not-valided
      v-if="accountNotValid"
      @emitCancelCreateNotValid="emitCancelCreateNotValid"
      @emitSaveCreateNotValid="wantToRecharge(false)"
    />
    <save-audience
      v-if="wantToSaveAudience"
      :data="audienceData"
      @emitCloseSuccess="closeAdienceSave"
      @emitSuccessCreateEnd="closeAdienceSave"
    />
  </div>
</template>

<script>
import testImage from '../../../assets/images/photo4.png'
import { Datetime } from 'vue-datetime'
import VueMultiselect from 'vue-multiselect/src/Multiselect'
import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import moment from 'moment'
import TWEEN from 'tween.js'
import { remaDefaultData } from '../../../rema-function/remaDefaultData'
import URLCHECKER from 'is-url'
import firebaseDatabase from '../../../rema-function/firebaseDatabase'
import firebase from 'firebase'
import ConfirmPostDebit from './confirm-post-debit'
import InsufficientAmount from './insufficient-amount'
import SuccessCreate from './success-create'
import MyCustomLoader from './my-custom-loader'
import AccountNotValided from './account-not-validated'
import myCities from '../../../assets/africanCountries'
import allCountries from '../../../assets/allcountries.json'
import SaveAudience from './save-audience'
export default {
  name: 'SmsCampaignInfos',
  components: {
    AccountNotValided,
    MyCustomLoader,
    SuccessCreate,
    InsufficientAmount,
    ConfirmPostDebit,
    datetime: Datetime,
    VueMultiselect,
    SaveAudience
  },
  props: {
    campaignForU: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isUpdateImage: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      audience: [],
      selectedAudience: {},
      wantToSaveAudience: false,
      audienceData: {},
      allMacthUserPhone: [],
      entreprise: {},
      showLoader: false,
      wantToConfirmCreate: false,
      insufficientAmount: false,
      sucessCreate: false,
      campaignName: '',
      campaignText: '',
      tesImage: testImage,
      images: {
        imagesReal: [],
        imageDataUrl: []
      },
      allCites: myCities,
      accountNotValid: false,
      wantToChoseButton: false,
      actionButtons: [
        {
          label: this.$t('knowMore'),
          value: 'more'
        },
        {
          label: this.$t('download'),
          value: 'download'
        },
        {
          label: this.$t('createAccount'),
          value: 'register'
        },
        {
          label: this.$t('contactButton'),
          value: 'contact'
        },
        {
          label: this.$t('buyButton'),
          value: 'buy'
        },
        {
          label: this.$t('participate'),
          value: 'participate'
        },
        {
          label: this.$t('apply'),
          value: 'apply'
        },
        {
          label: this.$t('noneButton'),
          value: 'none'
        }
      ],
      selectedButton: [],
      buttonLink: '',
      campaignStartDate: this.timeFromTimeStamp(new Date().getTime()),
      dateTooltip: 'Date de début de la pub',
      countryList: [],
      contriesListWithPays: [],
      selectedCountry: [],
      selectedCity: [],
      professionsData: [
        { id: 5, text: 'ENSEIGNANT' },
        { id: 4, text: 'SPECIALISTE' },
        { id: 3, text: 'MEDECIN EN SPECIALISATION' },
        { id: 2, text: 'MEDECIN GENERALISTE' },
        { id: 7, text: 'SAGE-FEMME' },
        { id: 6, text: 'INFIRMIER' },
        { id: 1, text: 'ETUDIANT' }
      ],
      selectedProfession: [],
      ageData: [
        {
          id: 0,
          text: '18 à 22ans',
          startBy: 18,
          endBy: 22
        },
        {
          id: 1,
          text: '23 à 30ans',
          startBy: 23,
          endBy: 30
        },
        {
          id: 2,
          text: '31 à 44ans',
          startBy: 31,
          endBy: 44
        },
        {
          id: 3,
          text: '45ans et plus',
          startBy: 45,
          endBy: 200
        }
      ],
      selectedAge: [],
      selectedSex: [],
      sexData: [
        {
          id: 0,
          label: 'Femmes'
        },
        {
          id: 1,
          label: 'Hommes'
        },
        {
          id: 2,
          label: 'Tout'
        }
      ],
      nbrDay: 1,
      nbrDoc: 0,
      posterValue: 0,
      posterRealValue: 0,
      porterUids: [],
      totalCost: 0,
      campaign: {
        campaignText: '',
        campaignName: '',
        images: {
          imagesReal: [],
          imageDataUrl: []
        },
        selectedButton: [],
        buttonLink: '',
        campaignStartDate: new Date().toISOString(),
        selectedCountry: '',
        selectedProfession: [],
        selectedAge: [],
        selectedSex: [],
        nbrDay: 0,
        posterValue: 0,
        porterUids: [],
        totalCost: 0
      },
      wallet: {},
      user: {}
    }
  },
  computed: {
    maxChar: function () {
      if (typeof this.campaignText.length !== 'undefined' && typeof this.entreprise.fullname !== 'undefined') {
        return 160 - (this.entreprise.fullname.length + this.campaignText.length)
      } else {
        return 160
      }
    },
    porteeProbable: function () {
      return this.porterUids.length * remaDefaultData.coef
    },
    postData: function () {
      let dat = ''
      if (this.campaignStartDate !== '') {
        dat = this.timeFromTimeStampTwo(this.campaignStartDate)
      } else {
        dat = new Date().toLocaleDateString('en-GB')
      }
      let sexe = 0
      if (typeof this.selectedSex.id === 'undefined') {
        sexe = 2
      } else {
        sexe = this.selectedSex.id
      }
      let current = new Date()
      let time = current.getHours() + ':' + current.getMinutes()
      return {
        postTitre: this.campaignName,
        postDescription: this.campaignText,
        entrepriseName: this.entreprise.fullname,
        imagesPath: this.images.imageDataUrl,
        postStartDate: dat,
        postStartTime: time,
        nbrJour: this.nbrDay,
        postCout: this.totalCost,
        porteeProbable: Math.round(Number.parseInt(this.porteeProbable) * 1.6),
        phoneArray: this.allMacthUserPhone,
        deviseSelected: { id: 2, text: '€' },
        professionSelected: this.selectedProfession,
        paysSelected: this.selectedCountry,
        citySelected: this.selectedCity,
        ageSelected: this.selectedAge,
        sexe: sexe,
        postButton: this.selectedButton,
        postButtonLink: this.buttonLink
      }
    },
    allSpeCities: function () {
      let result = []
      if (typeof this.selectedCountry !== 'undefined') {
        if (this.selectedCountry.length === 0) {
          result = this.allCites
        } else {
          for (let i = 0; i < this.allCites.length; i++) {
            console.log(this.allCites[i])
            for (let x = 0; x < this.selectedCountry.length; x++) {
              console.log(this.selectedCountry[x].callingCodes)
              if (this.selectedCountry[x].callingCodes === this.allCites[i].code) {
                result.push(this.allCites[i])
              }
            }
          }
        }
      }
      return result.sort()
    },
    allSpeCitiesStringArray: function () {
      let all = []
      if (typeof this.allSpeCities !== 'undefined') {
        for (let i = 0; i < this.allSpeCities.length; i++) {
          if (!this.selectedVilleCodeString.includes(this.allSpeCities[i].code)) {
            all.push(this.allSpeCities[i].city.toLowerCase())
          }
        }
      }
      return all
    },
    selectedVilleCodeString: function () {
      let rr = []
      if (typeof this.selectedCity !== 'undefined') {
        for (let i = 0; i < this.selectedCity.length; i++) {
          rr.push(this.selectedCity[i].code)
        }
      }
      return rr
    }
  },
  watch: {
    porteeProbable: function (newValue, oldValue) {
      let vm = this
      function animate () {
        if (TWEEN.update()) {
          requestAnimationFrame(animate)
        }
      }

      // TODO HERE IF fff
      new TWEEN.Tween({ tweeningNumber: oldValue })
        .easing(TWEEN.Easing.Quadratic.Out)
        .to({ tweeningNumber: newValue }, 3000)
        .onUpdate(function () {
          // vm.posterValue = Math.round(this.tweeningNumber.toFixed(0) * 2) + Math.floor(Math.random() * 9) + 1
          vm.$emit('emitLikelyReach', Math.round(this.tweeningNumber.toFixed(0) * 1.6))
          vm.posterRealValue = Math.round((this.tweeningNumber / 1.15).toFixed(0))
          vm.posterValue = Math.round(this.tweeningNumber.toFixed(0) * 1.6)
          vm.totalCost = Math.round(vm.posterRealValue * remaDefaultData.baseSMSPrice)
          vm.$emit('emitTotalCost', vm.totalCost)
        })
        .start()

      animate()
    },
    'selectedProfession': function (newValue) {
      // let difference = newValue.filter(x => !oldValue.includes(x)).concat(oldValue.filter(x => !newValue.includes(x)))
      this.cibleWatching()
    },
    'selectedCity': function (newValue) {
      this.cibleWatching()
    },
    'selectedCountry': function (newValue) {
      // this.fields.paysCible.valid = true
      // let difference = newValue.filter(x => !oldValue.includes(x)).concat(oldValue.filter(x => !newValue.includes(x)))
      this.cibleWatching()
    },
    'selectedSex': function () {
      this.cibleWatching()
    },
    'selectedAge': function () {
      this.cibleWatching()
    },
    'nbrDay': function (oldValue, newValue) {
      function animate () {
        if (TWEEN.update()) {
          requestAnimationFrame(animate)
        }
      }
      let vm = this
      new TWEEN.Tween({ tweeningNumber: oldValue })
        .easing(TWEEN.Easing.Quadratic.Out)
        .to({ tweeningNumber: newValue }, 3000)
        .onUpdate(function () {
          // vm.totalCost= Math.round(this.tweeningNumber.toFixed(0) * 2) + Math.floor(Math.random() * 9) + 1
          vm.totalCost = Math.round(vm.posterRealValue * remaDefaultData.baseSMSPrice)
          vm.$emit('emitTotalCost', vm.totalCost)
        })
        .start()
      animate()
    }
  },
  async created () {
    let vm = this
    this.countryList = []
    var data = allCountries
    data.forEach((val) => {
      if (/^2/.test(val.callingCodes[0])) {
        vm.countryList.push({
          name: val.name,
          alpha2Code: val.alpha2Code,
          alpha3Code: val.alpha3Code,
          callingCodes: val.callingCodes[0],
          text: val.translations.fr
        })
      }
    })
    if (this.isUpdate) {
      this.campaignName = this.campaignForU.title
      this.campaignText = this.campaignForU.textContent
      this.selectedCountry = this.campaignForU.paysCible
      this.nbrDay = this.campaignForU.postDuree
      this.selectedAge = this.campaignForU.ageCible
      this.selectedProfession = this.campaignForU.professionsCible
      this.selectedCity = this.campaignForU.cityCible
      this.allSpeCitiesStringArray = this.campaignForU.othersCities
      if (this.campaignForU.sexeCible === 0) {
        this.selectedSex = this.sexData[0]
      }
      if (this.campaignForU.sexeCible === 1) {
        this.selectedSex = this.sexData[1]
      }
      if (this.campaignForU.sexeCible === 2) {
        this.selectedSex = this.sexData[2]
      }
      // this.selectedSex = this.campaignForU.sexeCible
      this.selectedButton = this.campaignForU.postButton
      this.buttonLink = this.campaignForU.postButtonLink
      this.images.imageDataUrl = this.isUpdateImage
    }
  },
  methods: {
    isInCity (code) {
      for (let i = 0; i < this.selectedCountry.length; i++) {
        if (this.selectedCountry[i].callingCodes === code) {
          return
        }
      }
    },
    emitCloseSuccess (answer) {
      this.sucessCreate = answer
    },
    emitCloseInsufficient () {
      this.insufficientAmount = false
    },
    emitCloseConfirm () {
      this.wantToConfirmCreate = false
    },
    emitCampaignName () {
      this.$emit('getCampaignName', this.campaignName)
    },
    emitCampaignText () {
      this.$emit('getCampaignText', this.campaignText)
    },
    showSelect () {
      let input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('multiple', 'multiple')
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        let files = e.target.files
        let file = e.target.files.length
        for (let i = 0; i < file; i++) {
          this.createImage(files[i])
          this.images.imagesReal.push(files[i])
          this.$emit('selectedImages', this.images)
        }
      }
      input.setAttribute('capture', 'camera')
      input.click()
    },
    createImage (file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.images.imageDataUrl.push(e.target.result)
      }
      return reader.readAsDataURL(file)
    },
    deleteImage (position) {
      this.images.imagesReal.splice(position, 1)
      this.images.imageDataUrl.splice(position, 1)
      this.$emit('selectedImages', this.images)
    },
    emitActionButton () {
      this.$emit('choosedButton', this.selectedButton)
    },
    emitButtonLink () {
      this.$emit('getButtonLink', this.buttonLink)
    },
    customLabel (option) {
      return `${option.text}`
    },
    customLabelSex (option) {
      return `${option.label}`
    },
    customLabelButton (option) {
      return `${option.label}`
    },
    checkPrevision (statutPro, countryCode, male, ville) {
      this.showLoader = true
      let vm = this
      firebaseDb.ref(firebaseRef.users).orderByChild('isAds').equalTo(false).on('value', function (snapshot) {
        vm.allMacthUserPhone = []
        snapshot.forEach(function (childSnapshot) {
          if (childSnapshot.val().active === true && typeof childSnapshot.val().remaUserInfos['city'] !== 'undefined') {
            console.log(childSnapshot.val().remaUserInfos['city'])
            if ((ville.includes(childSnapshot.val().remaUserInfos['city'].toLowerCase()) || vm.allSpeCitiesStringArray.includes(childSnapshot.val().remaUserInfos['city'].toLowerCase())) && countryCode.includes(childSnapshot.val().remaUserInfos['countryCode']) && statutPro.includes(childSnapshot.val().remaUserInfos['statutPro']) && !(childSnapshot.val().remaUserInfos['male'] === male) && vm.checkHaveAges(childSnapshot.val().remaUserInfos['dateNaiss'])) {
              if (!vm.porterUids.includes(childSnapshot.val().uid)) {
                vm.porterUids.push(childSnapshot.val().uid)
                console.log(childSnapshot.val().remaUserInfos['countryCode'] + childSnapshot.val().remaUserInfos['tel'])
                // alert('hier')
              }
              if (!vm.allMacthUserPhone.includes((childSnapshot.val().remaUserInfos['countryCode'] + childSnapshot.val().remaUserInfos['tel']))) {
                vm.allMacthUserPhone.push((childSnapshot.val().remaUserInfos['countryCode'] + childSnapshot.val().remaUserInfos['tel']))
              }
              // alert('hier')
              //   alert(vm.porterUids.length)
            } else {
              if (vm.porterUids.includes(childSnapshot.val().uid)) {
                // console.log(childSnapshot.val().remaUserInfos['male'] + '_______' + childSnapshot.val().remaUserInfos['countryCode'] + '_______' + childSnapshot.val().remaUserInfos['statutPro'])
                let index = vm.porterUids.indexOf(childSnapshot.val().uid)
                // alert('ici 2')
                if (index > -1) {
                  vm.porterUids.splice(index, 1)
                  //  alert('ici 3')
                }
              }
            }
          }
        })
        setTimeout(() => {
          vm.showLoader = false
        }, 500)
      })
      // this.showLoader = false
    },
    cibleWatching: function () {
      // let vm = this
      let tabPro = []
      let tabPays = []
      let tabSexe = null
      let tabCity = []
      if (typeof this.selectedCity !== 'undefined') {
        for (let i = 0; i < this.selectedCity.length; i++) {
          tabCity.push(this.selectedCity[i].city.toLowerCase())
        }
        if (this.selectedCity.length === 0) {
          for (let i = 0; i < this.allCites.length; i++) {
            tabCity.push(this.allCites[i].city.toLowerCase())
          }
        }
      }

      if (typeof this.selectedProfession !== 'undefined') {
        for (let i = 0; i < this.selectedProfession.length; i++) {
          tabPro.push(this.selectedProfession[i]['text'])
        }
        if (this.selectedProfession.length === 0) {
          for (let i = 0; i < this.professionsData.length; i++) {
            tabPro.push(this.professionsData[i]['text'])
          }
        }
      }

      if (typeof this.selectedCountry !== 'undefined') {
        for (let j = 0; j < this.selectedCountry.length; j++) {
          tabPays.push('+' + this.selectedCountry[j]['callingCodes'])
        }
        switch (this.selectedSex.id) {
          case 0:
            tabSexe = true
            break
          case 1:
            tabSexe = false
            break
          default:
            tabSexe = null
        }
      }
      this.checkPrevision(tabPro, tabPays, tabSexe, tabCity)
    },
    checkHaveAges: function (datenesss) {
      let ageActu = moment().locale('fr').format('YYYY') - datenesss.split('-')[0]
      for (let k = 0; k < this.selectedAge.length; k++) {
        if (ageActu >= this.selectedAge[k]['startBy'] && ageActu <= this.selectedAge[k]['endBy']) {
          // console.log(ageActu)
          return true
        }
      }
      if (this.selectedAge.length === 0) {
        for (let k = 0; k < this.ageData.length; k++) {
          if (ageActu >= this.ageData[k]['startBy'] && ageActu <= this.ageData[k]['endBy']) {
            return true
          }
        }
      }
      return false
    },
    isEmpty (obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) { return false }
      }

      return true
    },
    checkExistDescription () {
      if (this.campaignText !== '') {
        return true
      } else {
        this.$toast.error(this.$t('errorCampaignText'))
        return false
      }
    },
    checkButtonLink () {
      if (this.isEmpty(this.selectedButton)) {
        return true
      } else {
        if (this.buttonLink === '') {
          this.$toast.error(this.$t('erroRedirectLink'))
          return false
        } else {
          if (URLCHECKER(this.buttonLink)) {
            return true
          } else {
            this.$toast.error(this.$t('errorValidRedirectLink'))
            return false
          }
        }
      }
    },
    checkImages () {
      if (this.images.imagesReal.length !== 0) {
        return true
      } else {
        this.$toast.error(this.$t('errorImages'))
        return false
      }
    },
    checkExistName () {
      if (this.campaignName !== '') {
        return true
      } else {
        this.$toast.error(this.$t('errorCampaignName'))
        return false
      }
    },
    setDefaultStart () {
      this.buttonLink = 'https://www.'
    },
    timeFromTimeStamp (date) {
      let timeStamp = new Date(date).getTime()
      let obj = new Date(timeStamp)
      return obj.toLocaleDateString()
    },
    timeFromTimeStampTwo (timeStamp) {
      let obj = new Date(timeStamp)
      return obj.toLocaleDateString('en-GB')
    },
    createSpecificPostData () {
      // let self = this
      if (!(this.postData.professionSelected.length > 0)) {
        this.postData.professionSelected = this.professionsData
      }
      if (!(this.postData.ageSelected.length > 0)) {
        this.postData.ageSelected = this.ageData
      }
      let professionCibleStr = ''
      if (this.postData.professionSelected.length !== this.professionsData.length) {
        for (let i = 0; i < this.postData.professionSelected.length; i++) {
          professionCibleStr += this.postData.professionSelected[i].text + ','
        }
      } else {
        professionCibleStr = '*'
      }
      let paysCibleStr = ''
      if (this.postData.paysSelected.length !== this.countryList.length) {
        for (let i = 0; i < this.postData.paysSelected.length; i++) {
          paysCibleStr += this.postData.paysSelected[i].callingCodes + ','
        }
      } else {
        paysCibleStr = '*'
      }
      let ageCibleStr = ''
      if (this.postData.ageSelected.length !== this.ageData.length) {
        for (let i = 0; i < this.postData.ageSelected.length; i++) {
          ageCibleStr += this.postData.ageSelected[i].startBy + '-' + this.postData.ageSelected[i].endBy + ','
        }
      } else {
        ageCibleStr = '*'
      }
      let cityCibleStr = ''
      if (typeof this.selectedCity !== 'undefined') {
        if (this.selectedCity.length !== 0) {
          for (let i = 0; i < this.selectedCity.length; i++) {
            cityCibleStr += this.selectedCity[i].city + ','
          }
        } else {
          cityCibleStr = '*'
        }
      } else {
        cityCibleStr = '*'
        this.selectedCity = []
      }
      this.postData.professionCibleStr = professionCibleStr
      this.postData.paysCibleStr = paysCibleStr
      this.postData.ageCibleStr = ageCibleStr
      this.postData.cityCibleStr = cityCibleStr
      this.postData.othersCities = this.allSpeCitiesStringArray
      console.log(this.postData)
    },
    publishPost () {
      if (this.checkExistName()) {
        if (this.checkExistDescription()) {
          if (!this.user.adsInfos.isValided) {
            this.accountNotValid = true
          } else {
            if (this.totalCost === 0) {
              this.$toast.info(this.$t('waitCost'))
            } else {
              //  let mo = 10
              // this.wallet.balance
              this.wantToConfirmCreate = true

              // REMOVE PAIEMENT SYSTEM.
              // if (typeof this.wallet.balance === 'undefined') {
              //   this.insufficientAmount = true
              // } else {
              //   if (this.wallet.balance < this.totalCost) {
              //     this.insufficientAmount = true
              //   } else {
              //     this.wantToConfirmCreate = true
              //   }
              // }
            }
          }
        }
      }
    },
    wantToRecharge (item) {
      let vm = this
      this.insufficientAmount = false
      this.showLoader = true
      this.accountNotValid = false
      this.createSpecificPostData()
      firebaseDatabase.saveInDraftSMS(this.postData, function (res) {
        window.setTimeout(function () {
          if (res) {
            if (item) {
              vm.showLoader = false
              vm.$toast.success(vm.$t('sucessSaveIndraft'))
              vm.$router.push('/wallet')
            } else {
              vm.showLoader = false
              vm.$toast.success(vm.$t('sucessSaveIndraft'))
              vm.$store.dispatch('createState', true)
              vm.$router.push('/cards')
              vm.$emit('successSave', true)
            }
          } else {
            alert('non drafter')
          }
        }, 5000)
      })
    },
    emitCancelCreateNotValid () {
      this.accountNotValid = false
      this.$router.push('/dashboard')
    },
    essaiYER () {
      this.$toast.success(this.$t('errorCampaignName'))
    },
    listUserFirebase () {
      let tabb = []
      let i = 0
      // let vm = this
      firebase.database().ref('/users')
        .once('value', function (snapshot) {
          console.log(snapshot.numChildren())
          snapshot.forEach(function (el, index) {
            let cod = el.child('remaUserInfos').child('countryCode').val()
            let stat = el.child('remaUserInfos').child('statutPro').val()
            if (cod !== null) {
              if ((cod.toString() === '+229' && stat.toLowerCase() === 'MEDECIN EN SPECIALISATION'.toLowerCase()) || (cod.toString() === '+' && stat.toLowerCase() === 'MEDECIN EN SPECIALISATION'.toLowerCase())) {
                el.child('remaUserInfos').child('countryCode').ref.set('+229')
                console.log(cod)
                console.log(index)
                console.log('Ctn   :' + el.key)
                el.child('remaUserInfos').child('city').ref.set('cotonou')
                tabb.push(cod)
                i++
                // el.child('remaUserInfos').child('city').ref.set('cotonou')
              }
            }
          })
          console.log('terminer')
          console.log(tabb.length)
          console.log(i)
        })
    },
    getAllCities () {
      fetch('https://wft-geo-db.p.rapidapi.com/v1/geo/cities', {
        'method': 'GET',
        'headers': {
          'x-rapidapi-host': 'wft-geo-db.p.rapidapi.com',
          'x-rapidapi-key': '7256bddb42msh4f489ea0d5eb800p149fe8jsn4832f23d62c4',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAfricanCities () {
      for (let i = 0; i < this.allCites.length; i++) {
        if (this.isIn(this.allCites[i].country_code)) {
          this.africanCities.push({
            code: this.getCoutryCode(this.allCites[i].country_code),
            city: this.allCites[i].name
          })
        }
      }
    },
    isIn (code) {
      let result = false
      for (let i = 0; i < this.countriesList.length; i++) {
        if (this.countriesList[i].alpha2Code === code) {
          result = true
        }
      }
      return result
    },
    getCoutryCode (code) {
      let result = ''
      if (this.isIn(code)) {
        for (let i = 0; i < this.countriesList.length; i++) {
          if (this.countriesList[i].alpha2Code === code) {
            result = this.countriesList[i].code
          }
        }
      }
      return result
    },
    getCapital (code) {
      let result = ''
      for (let i = 0; i < this.contriesList.length; i++) {
        if (this.contriesList[i].code === code) {
          result = this.contriesList[i].capital
        }
      }
      return result
    },
    getCode (city) {
      let result = ''
      for (let i = 0; i < this.contriesList.length; i++) {
        if (this.contriesList[i].capital === city) {
          result = this.contriesList[i].code
        }
      }
      return result
    },
    saveNewPost () {
      let vm = this
      this.wantToConfirmCreate = false
      this.showLoader = true
      this.createSpecificPostData()
      firebaseDatabase.createNewSMSPost(this.postData, function (res) {
        window.setTimeout(function () {
          if (res) {
            vm.showLoader = false
            // vm.$toast.success(vm.$t('postpublished'))
            vm.$emit('successSave', true)
            vm.$store.dispatch('createState', true)
            vm.$router.push('/cards')
            vm.sucessCreate = true
          } else {
            alert('Non publier!')
          }
        }, 5000)
      })
    },
    emitSuccessCreateEnd () {
      this.sucessCreate = false
      this.$store.dispatch('createState', true)
      this.$router.push('/cards')
    },
    async savePublic () {
      if (this.totalCost !== 0) {
        const data = {
          selectedAge: this.selectedAge,
          selectedProfession: this.selectedProfession,
          selectedCity: this.selectedCity,
          selectedCountry: this.selectedCountry,
          selectedSex: this.selectedSex,
          nbrDay: this.nbrDay
        }
        console.log(data)
        console.log(firebase.auth().currentUser.uid)
        this.audienceData = data
        this.wantToSaveAudience = true
      } else {
        this.$toast.error('Vous ne pouvez pas sauvegarder une audience vide')
      }
    },
    closeAdienceSave () {
      this.wantToSaveAudience = false
    },
    updateAudience () {
      if (this.selectedAudience['selectedAge'] != null) {
        this.selectedAge = this.selectedAudience['selectedAge']
      } else {
        this.selectedAge = []
      }
      if (this.selectedAudience['selectedProfession'] != null) {
        this.selectedProfession = this.selectedAudience['selectedProfession']
      } else {
        this.selectedProfession = []
      }

      if (this.selectedAudience['selectedCity'] != null) {
        this.selectedCity = this.selectedAudience['selectedCity']
      } else {
        this.selectedCity = []
      }
      if (this.selectedAudience['selectedCountry'] != null) {
        this.selectedCountry = this.selectedAudience['selectedCountry']
      } else {
        this.selectedCountry = []
      }
      if (this.selectedAudience['selectedSex'] != null) {
        this.selectedSex = this.selectedAudience['selectedSex']
      } else {
        this.selectedSex = []
      }
      if (this.selectedAudience['nbrDay'] != null) {
        this.nbrDay = this.selectedAudience['nbrDay']
      } else {
        this.nbrDay = 1
      }
    }
  },
  firebase () {
    return {
      wallet: firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid),
      user: firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid),
      entreprise: firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid),
      audience: firebaseDb.ref(firebaseRef.pubAudience).child(firebase.auth().currentUser.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .divider {
    background-color: $Green-Rema;
  }
  .color-grenn {
    background-color: black !important;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  textarea {
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: .2; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: .2; /* Firefox */
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    opacity: .2; /* Firefox */
  }
  .selected-image {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
  .add-section {
    background-color: $Rema-Content-Background;
    line-height: 4rem;
  }
  .select-list{
    height: 8rem;
    overflow-y: scroll;
  }
  .amount-and-port {
    background-color: $Rema-Icon-Background;
  }
  .custom-style-tag /deep/ {
    div {
      ul {
        background-color: transparent;
      }
      div {
        span {
          background-color: $Rema-Content-Background;
          color: $Green-Rema;
          border: solid 1px $border_gray;
          text-transform: capitalize;
          span {
            border: none !important;
          }
          i:hover {
            background-color: $Green-Rema;
          }
        }
      }
    }
  }
  .popper {
    box-shadow: none;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    color: black;
    font-size: .7rem;
  }
  .rema-loader {
    margin-top: 18%;
    margin-left: 20%;
  }
  .my-date-time /deep/ {
    div {
      div {
        div .vdatetime-popup__header /deep/ {
          background-color: black;
        }
      }
    }
  }
  .vdatetime-popup__header /deep/ {
    background-color: black;
  }
  .canpaigns-infos{
  }
  .toasted .bubble .error {
    background: $Green-Rema !important;
  }
  .your-custom-class {
    background-color: green !important;
  }
  .toasted-container /deep/ {
    div {
      background-color: green !important;
    }
  }
  .toasted .primary .info .toasted .toasted-primary .info {
    background: red !important;
  }

</style>
